/**
 * Make API request for sailings list
 */
import { addMonths, lastDayOfMonth } from 'date-fns';

import { getSearchParams } from '@/ducks/routes/history';
import { fetchVoyageListData } from '@/helpers/api/app';
import { format, parse } from '@/helpers/util/dateUtil';
import { getBookingSource } from '@/helpers/util/misc';
import { getSessionStorageValue } from '@/helpers/util/storage';

// Build search qualifier structure for search sailings API request
const buildSearchQualifier = (filters) => {
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const currentEndDate = format(lastDayOfMonth(addMonths(parse(currentDate), 2)));
  const lockitinrateblitz = getSessionStorageValue('lockitinrateblitz');
  const { cabinType = null } = getSearchParams();
  let preferencesValue = [];
  if (lockitinrateblitz && cabinType && (cabinType === 'TZ' || cabinType === 'IZ')) {
    preferencesValue = [
      {
        categoryCode: cabinType,
      },
    ];
  } else {
    preferencesValue = filters.cabinType ? [{ metaCode: filters.cabinType }] : [];
  }

  return {
    searchQualifier: {
      accessKeys: filters.accessKeys || [],
      cabins: [
        {
          guestCounts: [
            {
              ageCategory: 'Adult',
              count: parseInt(filters.sailors, 10) || 2,
            },
          ],
        },
      ],
      currencyCode: filters.currencyCode || 'USD',
      preferences: preferencesValue,
      sailingDateRange: [
        {
          end: filters.toDate || currentEndDate,
          start: filters.fromDate || currentDate,
        },
      ],
    },
    ...getBookingSource(),
  };
};

export const makeApiCall = (filters) => fetchVoyageListData(buildSearchQualifier(filters));
