import { WHATS_INCLUDED_ERROR, WHATS_INCLUDED_SUCCESS } from '@/constants/actionTypes';
import { fetchWhatsIncludedData } from '@/helpers/api/app';

export const fetchWhatsIncludedDataAction = () => async (dispatch) => {
  let data = {};
  try {
    data = await fetchWhatsIncludedData();
    dispatch({
      payload: data,
      type: WHATS_INCLUDED_SUCCESS,
    });
  } catch (err) {
    dispatch({
      payload: null,
      type: WHATS_INCLUDED_ERROR,
    });
  }
  return data;
};

export default fetchWhatsIncludedDataAction;
