import type { RootState } from '@/store';

export const selectVoyagePlanner = (state: RootState) => state.voyagePlanner;

export const selectSailorDetails = (state: RootState) => selectVoyagePlanner(state)?.sailorDetails;

export const selectInsuranceFlyoutData = (state: RootState) =>
  selectSailorDetails(state)?.travelInsuranceFlyoutData?.data;

export const selectSailorData = (state: RootState) => selectSailorDetails(state)?.sailorData?.data;

export const selectPrimarySailor = (state: RootState) => selectSailorData(state)?.primarySailor;

export const selectWhatsIncluded = (state: RootState) => selectVoyagePlanner(state)?.whatsIncluded?.data;
export const selectIsWhatsIncludedLoaded = (state: RootState) => selectVoyagePlanner(state)?.whatsIncluded?.loaded;

export const selectIsFilteringLoading = (state: RootState) =>
  state?.chooseVoyageNew?.isLoading === true || state?.chooseVoyageNew?.isLoading === null;

export const selectIsFilteringLoaded = (state: RootState) => state?.chooseVoyageNew?.mainPackages.isLoaded === true;
