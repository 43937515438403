import isEmpty from 'lodash/isEmpty';

import type {
  TCabinHoldApiArgs,
  TCabinHoldApiResultItem,
  TCabinHoldData,
  TCabinUnholdApiArgs,
} from '@/infra/types/cabin';
import type { TISODateTime, TUrlParamCurrencyCode, TUrlParamVoyageId } from '@/infra/types/common';
import type { AppDispatch, AppGetState } from '@/store';
import type { TOptional } from '@/types/common';

import { selectSailingData } from '@/ducks/pages/summary/selectors';
import { cabinHold } from '@/helpers/api/app';
import { makeUnholdApiArgs } from '@/helpers/data/mappers/Summary';
import { getBookingSource } from '@/helpers/util/misc';

import makeHoldCabinDetailArg, { type TCabinInvoiceSlice } from '../helpers/makeHoldCabinDetailArg';
import { cabinHoldClear, cabinHoldSetData, cabinHoldSetError } from '../slice';

export const HOLD_MAX_TIME = 60 * 60000; // 1 hour as in prod

export { cabinHoldClear as cabinHoldClearAction };

export const cabinHoldAction =
  (args?: TOptional<TCabinHoldApiArgs>) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<TOptional<TCabinHoldData>> => {
    const { cabinDetails, currencyCode, voyageId } = args || {};
    const hasArgs = !!(currencyCode && voyageId && !isEmpty(cabinDetails));
    try {
      const holdData: TCabinHoldApiResultItem[] = hasArgs ? await cabinHold(args) : [];
      const { cabinNumber, dueDateTime } = holdData?.[0] || {};
      const hasResult = !!cabinNumber;
      const holdTime = hasArgs ? new Date() : undefined;
      const minUnholdTime = hasResult ? calcMinUnholdTime(dueDateTime!, holdTime!) : undefined;

      const data: TCabinHoldData = {
        holdData,
        holdTime: holdTime?.toISOString(),
        minUnholdTime,
      };

      if (hasResult) {
        const sailingData = selectSailingData(getState());
        if (sailingData?.debarkDate && sailingData?.embarkDate && sailingData?.voyageId) {
          data.unholdArgs = makeUnholdApiArgs(cabinNumber, sailingData) as TCabinUnholdApiArgs;
        }
      }

      dispatch(cabinHoldSetData(data));
      return data;
    } catch (err) {
      dispatch(cabinHoldSetError((err as Error).message || `${err}`));
    }
  };

export const invoiceCabinsHoldAction =
  (cabinInvoices: TCabinInvoiceSlice[], parsedData: TUrlParamCurrencyCode & TUrlParamVoyageId) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { currencyCode, voyageId } = parsedData || {};
    if (!cabinInvoices?.length || !voyageId) {
      return;
    }

    await Promise.all(
      cabinInvoices
        .map((cabinInvoice: TCabinInvoiceSlice) => {
          const cabinDetailArg = makeHoldCabinDetailArg(cabinInvoice);
          return cabinDetailArg
            ? dispatch(
                cabinHoldAction({
                  cabinDetails: [cabinDetailArg],
                  currencyCode: currencyCode || 'USD',
                  voyageId: voyageId,
                  ...getBookingSource(currencyCode, false, false),
                }),
              )
            : undefined;
        })
        .filter(Boolean),
    );
  };

const calcMinUnholdTime = (unholdTime: TISODateTime, holdTime: Date): TISODateTime => {
  // forcely add delays for popups if unholdTime below than needed for 2 popups (see https://virginvoyages.atlassian.net/browse/MSH-98463)
  const overallTime = new Date(unholdTime).getTime() - (holdTime?.getTime() || 0);
  const popupPossible = overallTime > HOLD_MAX_TIME;
  const holdTimeWithMaxTime = (holdTime?.getTime() || 0) + HOLD_MAX_TIME;
  return popupPossible ? unholdTime : new Date(holdTimeWithMaxTime).toISOString();
};
