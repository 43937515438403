import {
  ADD_OTHER_GUEST_DETAILS_SUCCESS,
  CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_ERROR,
  CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_SUCCESS,
  UPDATE_RESERVATION_ERROR_STATUS,
} from '@/constants/actionTypes';
import { addOtherGuestDetails } from '@/helpers/api/app';

export const setTrackedReservationNumberAction = (data) => async (dispatch) => {
  try {
    dispatch({
      payload: data,
      type: CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_SUCCESS,
    });
  } catch (err) {
    dispatch({
      payload: err,
      type: CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_ERROR,
    });
  }
};

export const addOtherGuestDetailsAction = (dashboardData, guestsailor) => async (dispatch) => {
  try {
    const data = await addOtherGuestDetails(dashboardData, guestsailor);
    dispatch({
      payload: data,
      type: ADD_OTHER_GUEST_DETAILS_SUCCESS,
    });
  } catch (err) {
    const data = {
      showBookNowErrorStatus: true,
    };
    dispatch({
      payload: data,
      type: UPDATE_RESERVATION_ERROR_STATUS,
    });
  }
};

export default { addOtherGuestDetailsAction, setTrackedReservationNumberAction };
