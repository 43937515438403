import {
  CLEAR_SUMMARY_SAILING_DATA,
  SUMMARY_SAILING_DATA_ERROR,
  SUMMARY_SAILING_DATA_SUCCESS,
} from '@/constants/actionTypes';
import { throwError } from '@/ducks/common';
import { fetchItinerarySummaryData } from '@/helpers/api/app';

export const fetchSailingDataAction = (parsedParameters) => async (dispatch) => {
  let data = {};
  try {
    const { voyageId } = parsedParameters;

    if (typeof voyageId !== 'undefined' && voyageId) {
      data = await fetchItinerarySummaryData(parsedParameters);
      dispatch({
        payload: data,
        type: SUMMARY_SAILING_DATA_SUCCESS,
      });
    }
  } catch (err) {
    dispatch({
      payload: err,
      type: SUMMARY_SAILING_DATA_ERROR,
    });
    dispatch(throwError(err));
  }
  return data;
};

export const updateSailingData = (sailingData) => async (dispatch) => {
  dispatch({
    payload: sailingData,
    type: SUMMARY_SAILING_DATA_SUCCESS,
  });
};

export const clearSailingData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUMMARY_SAILING_DATA,
  });
};

export default fetchSailingDataAction;
