import { ACCOUNT_PROFILE_DATA_ERROR, ACCOUNT_PROFILE_DATA_SUCCESS } from '@/constants/actionTypes';

export const updateUserProfileDataAction = (response) => async (dispatch) => {
  let data = response;
  try {
    dispatch({
      payload: data,
      type: ACCOUNT_PROFILE_DATA_SUCCESS,
    });
  } catch (err) {
    data = err;
    dispatch({
      payload: data,
      type: ACCOUNT_PROFILE_DATA_ERROR,
    });
  }
  return data;
};

export default updateUserProfileDataAction;
