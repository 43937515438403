import type { TUrlParamCurrencyCode, TUrlParamVoyageId } from '@/infra/types/common';
import type { AppDispatch, AppGetState } from '@/store';
import type { TOptional } from '@/types/common';

import { selectCabinHold, selectCabinInvoices } from '@/ducks/pages/summary/selectors';

import makeHoldCabinDetailArg from '../helpers/makeHoldCabinDetailArg';
import selectUnholdArgs from '../selectors/selectUnholdArgs';
import { cabinHoldClearAction, invoiceCabinsHoldAction } from './hold';
import { cabinUnholdAction } from './unhold';

// Returns a promise if the action can be performed and returns nothing otherwise (In order for the result to immediately show whether the requested action can be performed)

export const tryCabinUnhold =
  (isForced?: TOptional<boolean>) =>
  (dispatch: AppDispatch, getState: AppGetState): TOptional<Promise<void>> => {
    const payload = selectUnholdArgs(getState(), isForced);
    if (payload) {
      return dispatch(cabinUnholdAction(payload)).then(async () => {
        await dispatch(cabinHoldClearAction());
      });
    }
  };

// Returns a promise if the action can be performed and returns nothing otherwise (In order for the result to immediately show whether the requested action can be performed)

export const tryCabinHold =
  (parsedData: TUrlParamCurrencyCode & TUrlParamVoyageId) =>
  (dispatch: AppDispatch, getState: AppGetState): TOptional<Promise<void>> => {
    const state = getState();
    const cabinInvoices = selectCabinInvoices(state);
    const { cabinNumber: currentCabinNumber, dueDateTime } = selectCabinHold(state)?.data?.holdData?.[0] || {};
    const { cabinNumber: nextCabinNumber } = makeHoldCabinDetailArg(cabinInvoices?.[0]) || {};
    if (nextCabinNumber) {
      let unholdPromise;
      if (currentCabinNumber) {
        if (nextCabinNumber === currentCabinNumber && new Date() < new Date(dueDateTime!)) {
          return Promise.resolve();
        }
        unholdPromise = dispatch(tryCabinUnhold());
        if (!unholdPromise) return;
      }
      return (unholdPromise || Promise.resolve()).then(async () => {
        await dispatch(invoiceCabinsHoldAction(cabinInvoices, parsedData));
      });
    }
  };
