import {
  RESET_LOCK_IT_IN_RATE_PROMO_STATE,
  SET_MULTIPLE_VOYAGE_FILTER_FLAG,
  applyFilters,
} from '@/ducks/pages/chooseVoyage';
import { removeSessionStorageValue, setSessionStorageValue } from '@/helpers/util';

export const removeMultipleVoyageFilter = () => async (dispatch) => {
  removeSessionStorageValue('voyageIds');
  await dispatch({
    type: RESET_LOCK_IT_IN_RATE_PROMO_STATE,
  });
  await dispatch(
    applyFilters({
      fetch: false,
    }),
  );
};

export const setMultipleVoyageFilter = (voyageId) => async (dispatch) => {
  setSessionStorageValue('voyageIds', voyageId);
  await dispatch({
    type: SET_MULTIPLE_VOYAGE_FILTER_FLAG,
  });
  await dispatch(
    applyFilters({
      fetch: false,
    }),
  );
};
