import isEmpty from 'lodash/isEmpty';

import {
  CLEAR_FUTURE_VOYAGE_CREDITS_DETAILS,
  FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_ERROR,
  FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_SUCCESS,
} from '@/constants/actionTypes';
import { fetchFVCDetails } from '@/helpers/api/app';

export const fetchFVCDetailsAction = (params) => async (dispatch) => {
  let data = [];
  try {
    data = await fetchFVCDetails(params);
    dispatch({
      payload: isEmpty(data) ? [] : data,
      type: FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_SUCCESS,
    });
  } catch (err) {
    dispatch({
      payload: null,
      type: FETCH_FUTURE_VOYAGE_CREDITS_DETAILS_ERROR,
    });
  }
  return isEmpty(data) ? [] : data;
};

export const clearFVCDetails = () => async (dispatch) => {
  dispatch({
    payload: {},
    type: CLEAR_FUTURE_VOYAGE_CREDITS_DETAILS,
  });
};

export default {
  clearFVCDetails,
  fetchFVCDetailsAction,
};
