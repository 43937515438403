import { selectLookup } from '@/ducks/common/selectors';
import { selectSailorData } from '@/ducks/pages/voyagePlanner/selectors';
import { optInForNews } from '@/helpers/api/app';
import { getCountryDialCode } from '@/helpers/util';

import { setSailorDataAction } from '../setSailorData';

export const subscribeForNewsAction = (sailorDetails) => (dispatch, getState) => {
  const previousSailorDetails = selectSailorData(getState());
  const lookup = selectLookup(getState());
  const isSubScribeForNewsToBeCalledForcely =
    previousSailorDetails?.primarySailor?.email &&
    (previousSailorDetails?.primarySailor?.isOptInForSMS !== sailorDetails?.primarySailor?.isOptInForSMS ||
      previousSailorDetails?.primarySailor?.add_newsletter !== sailorDetails?.primarySailor?.add_newsletter);

  dispatch(setSailorDataAction(sailorDetails));
  if (
    sailorDetails.primarySailor?.add_newsletter ||
    sailorDetails?.primarySailor?.isOptInForSMS ||
    isSubScribeForNewsToBeCalledForcely
  ) {
    return optInForNews({
      dateOfBirth: sailorDetails.primarySailor.dob,
      email: sailorDetails?.primarySailor?.email,
      firstName: sailorDetails.primarySailor.firstname,
      isOptInForEmail: sailorDetails.primarySailor?.add_newsletter || false,
      isOptInForSMS: sailorDetails.primarySailor?.isOptInForSMS || false,
      lastName: sailorDetails.primarySailor.lastname,
      notificationTypeCode: 'NO',
      phoneCountryCode: getCountryDialCode(lookup, sailorDetails?.primarySailor?.phoneCountryCode),
      phoneNumber: sailorDetails.primarySailor?.contactnumber,
    });
  }
  return Promise.resolve();
};

export default subscribeForNewsAction;
