import find from 'lodash/find';
import get from 'lodash/get';
import qs from 'query-string';

import { SUMMARY_GET_AVAILABLE_ZONES_ERROR, SUMMARY_GET_AVAILABLE_ZONES_SUCCESS } from '@/constants/actionTypes';
import { fetchAvailableZones } from '@/helpers/api/app';

export const fetchAvailableZonesAction = (availableZonesRequest) => async (dispatch) => {
  const zones = {};
  const zonesMap = [34, 33, 32, 40, 39, 38, 37, 36, 35]; // Mapping zone number to their respective index
  try {
    const data = await fetchAvailableZones(availableZonesRequest);
    const parsed = qs.parse(window.location.search);
    const isCabinAccessible = parsed.isCabinAccessible ? JSON.parse(parsed.isCabinAccessible) : false;
    for (let i = 0; i < zonesMap.length; i += 1) {
      const match = find(get(data, 'zones', []), (zone) => Number(get(zone, 'id')) === zonesMap[i]);
      zones[i + 1] = {
        cabinsAvailable:
          !!match || (match && (isCabinAccessible === false || match.isCabinAccessible === isCabinAccessible)),
        cabinsNearElevator: match ? get(match, 'isNearTheElevator') : false,
        description: match ? get(match, 'description') : '',
        soldOut: false,
        title: match ? get(match, 'name') : '',
        zoneId: zonesMap[i],
      };
    }

    dispatch({
      payload: zones,
      type: SUMMARY_GET_AVAILABLE_ZONES_SUCCESS,
    });
  } catch (err) {
    dispatch({
      payload: null,
      type: SUMMARY_GET_AVAILABLE_ZONES_ERROR,
    });
  }

  return zones;
};

export default fetchAvailableZonesAction;
