import { SAILOR_DETAILS_SET_DATA_ERROR, SAILOR_DETAILS_SET_DATA_SUCCESS } from '@/constants/actionTypes';

export const setSailorDataAction = (data) => async (dispatch) => {
  try {
    dispatch({
      payload: data,
      type: SAILOR_DETAILS_SET_DATA_SUCCESS,
    });
  } catch (err) {
    dispatch({
      payload: null,
      type: SAILOR_DETAILS_SET_DATA_ERROR,
    });
  }
  return data;
};

export default setSailorDataAction;
