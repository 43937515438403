import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_ERROR,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_SUCCESS,
  CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN,
} from '@/constants/actionTypes';
import { selectSupportedCurrencies } from '@/ducks/common/lookup/selectors';
import { routes } from '@/ducks/routes';
import { fetchCabinCategoriesData } from '@/helpers/api/app';

export const clearCabinCategoriesDataAction = () => async (dispatch) => {
  dispatch({
    payload: {},
    type: CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR,
  });
};

export const cabinCategorySetUnavailableCabinAction =
  (cabinCategoryCode, cabinTypeCode) => async (dispatch, getState) => {
    dispatch({
      payload: {
        cabinCategories: getState()?.voyagePlanner?.chooseCabin?.cabinCategories?.data,
        cabinCategoryCode,
        cabinTypeCode,
      },
      type: CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN,
    });
  };

export const fetchCabinCategoriesDataAction = (parsedParameters) => async (dispatch, getState) => {
  const state = getState();
  let data = {};
  try {
    const validateAccessKeyData = get(state, 'accessKeys.activeAccessKey', {});
    const supportedCurrencies = selectSupportedCurrencies(state);
    if (parsedParameters?.voyageId) {
      data = await fetchCabinCategoriesData(
        {
          ...parsedParameters,
          accessible: parsedParameters.isCabinAccessible,
          promoCode:
            !isEmpty(validateAccessKeyData) && get(validateAccessKeyData, 'filterSailing', false)
              ? get(validateAccessKeyData, 'promoCode', '')
              : undefined,
        },
        supportedCurrencies,
      );

      dispatch({
        payload: data,
        type: CABIN_SELECTION_CABIN_CATEGORIES_DATA_SUCCESS,
      });
    }
  } catch (err) {
    dispatch({
      payload: err,
      type: CABIN_SELECTION_CABIN_CATEGORIES_DATA_ERROR,
    });
    routes.page404.go();
  }
  return data;
};

export default {
  cabinCategorySetUnavailableCabinAction,
  clearCabinCategoriesDataAction,
  fetchCabinCategoriesDataAction,
};
