import { CONFIGURATION_SERVICE_PRICETYPE_CHANGED } from '@/constants/actionTypes';

export const priceTypeDataAction = (priceType) => async (dispatch) => {
  dispatch({
    payload: priceType,
    type: CONFIGURATION_SERVICE_PRICETYPE_CHANGED,
  });
};
export default {
  priceTypeDataAction,
};
