import get from 'lodash/get';

import { postSocialMediaPhoto } from '@/helpers/api/app';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import { getDXPAuth } from '@/helpers/getDXPAuth';

export const attemptSocialSignUpAction = (sdkResponse) => async () => {
  let response = '';
  const { dob: birthDate, email, firstname: firstName, lastname: lastName, photo, socialMediaId, type } = sdkResponse;
  try {
    let mediaPhotoId;

    if (photo) {
      try {
        const clientAccessToken = await AppAuthenticator.getInstance().getFreshAccessToken({
          tokenType: TokenType.guest,
        });
        const photoIdResponse = await postSocialMediaPhoto(photo, clientAccessToken);

        const mediaItemUrl = get(photoIdResponse, 'headers.location', '').split('mediaitems/');
        mediaPhotoId = mediaItemUrl.length > 1 ? mediaItemUrl[1] : photo;
      } catch (e) {
        // DO NOTHING
      }
    }

    const payload = {
      birthDate,
      email,
      firstName,
      lastName,
      socialMediaId,
    };
    if (mediaPhotoId) {
      payload.photo = mediaPhotoId;
    }

    const Auth = getDXPAuth();
    if (type === 'facebook') {
      response = await Auth.signUpFacebook(payload);
    } else if (type === 'google') {
      response = await Auth.signUpGoogle(payload);
    } else if (type === 'apple') {
      response = await Auth.signUpApple(payload);
    }
  } catch (err) {
    response = err;
  }
  return response;
};

export default attemptSocialSignUpAction;
