import { getDXPAuth } from '@/helpers/getDXPAuth';

export const attemptSocialLoginAction = (sdkResponse) => async () => {
  let response = {};
  const {
    email,
    firstname: firstName,
    lastname: lastName,
    // dob: birthDate,
    photo,
    socialMediaId,
    type,
  } = sdkResponse;
  try {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const Auth = getDXPAuth();
    if (type === 'facebook') {
      response = await Auth.signInFacebook({
        email,
        firstName,
        // birthDate,
        lastName,
        photo,
        socialMediaId,
      });
    } else if (type === 'google') {
      response = await Auth.signInGoogle({
        email,
        firstName,
        // birthDate,
        lastName,
        photo,
        socialMediaId,
      });
    } else if (type === 'apple') {
      response = await Auth.signInApple({
        email,
        firstName,
        lastName,
        socialMediaId,
      });
    }
  } catch (err) {
    err.status = 401;
    response = err;
  }
  return response;
};

export default attemptSocialLoginAction;
