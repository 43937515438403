import { SET_TOKEN_ERROR, SET_TOKEN_SUCCESS } from '@/constants/actionTypes';

export const setToken = (tokenType, tokenValue) => async (dispatch) => {
  try {
    dispatch({
      tokenType,
      tokenValue,
      type: SET_TOKEN_SUCCESS,
    });
  } catch (err) {
    dispatch({
      // SET_TOKEN_ERROR needs to be added in auth reducer
      payload: err,
      // after it changes to data{} and error{} format
      type: SET_TOKEN_ERROR,
    });
  }
  return tokenType, tokenValue;
};

export default setToken;
