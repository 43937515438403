import qs from 'query-string';

import { buildAgencyQuery } from '@/ducks/filters/util/query';
import { env } from '@/environment';

export const generateShareURL = (urlContext, shareType, pkgCode, voyageCode, metaCat) => {
  let params = `${urlContext}?type=${shareType}&pkgCode=${pkgCode}&voyageCode=${voyageCode}&metaCat=${metaCat}`;
  params = buildAgencyQuery(params);
  return params;
};

export const generateExtendedShareURL = (
  urlContext,
  shareType,
  packageCode,
  voyageId,
  referralCode,
  shipCode,
  isCelebrations = true,
) => {
  let params = `${urlContext}?type=${shareType}&pkgCode=${packageCode}&voyageCode=${voyageId}&referralCode=${referralCode}&shipCode=${shipCode}`;
  if (isCelebrations && shareType === 'P') {
    params = `${params}&referralType=CELEBRATIONS`;
  }
  params = buildAgencyQuery(params);
  return params;
};

export const generateFullCruiseShareURL = (parsed) => {
  const baseURL = env.VV_BASE_URL;
  const { cabins, currencyCode, fromDate, packageCode, priceType, sailors, toDate, voyageId } = parsed;
  const pathName = `${baseURL}book/voyage-planner/fullCruiseDetails`;
  const urlParams = qs.stringify({
    cabins,
    currencyCode,
    fromDate,
    packageCode,
    priceType,
    sailors,
    toDate,
    voyageId,
  });
  return `${pathName}?${urlParams}`;
};

export default {
  generateExtendedShareURL,
};
